<template>
  <nav class='top'>
    <div class='box'>
      <div class='LogoContainer'><a href='/' class='LogoContainer__logo'
          title='Conemoni トップページへ'>
          <img src='@/assets/img/conemoni_logo.svg' alt='Conemoni' width='180' height='60'>
        </a>
      </div>
      <div class='MenuContainer'>
        <ul>
          <li class='RemoteUser'>
            <div class='container' id='remote-player-status'>
            </div>
          </li>
          <li class='NameDisplay'>
            <div class='container'>
              <p>
                <span class='name'>{{ user.name }}({{ this.$store.state.uid }})さん</span><br>
                <span class='lastlogin'>最終ログイン：{{  }}</span>
              </p>
            </div>
          </li>
          <li class='MoneyDisplay'>
            <div class='MoneyBalanceDisplay'>
              <p class='top'>電子マネー残高</p>
              <div class='DisplayContainer'>
                <p>0<span class='currency'>円</span></p>
              </div>
            </div>
          </li>
          <li class='LogoutButton'>
            <a href='/logout' class='' title='ログアウト'>
              <div class='button'>
                <img src='@/assets/img/LogoutButton.png' alt='ログアウト' width='30' height='30'>
                <span class='text'>ログアウト</span>
              </div>
            </a>
          </li>
          <li class='AccountButton'>
            <a class='' title='アカウント'>
              <div class='button'>
                <img src='@/assets/img/AccountButton.png' alt='アカウント' width='30' height='30'>
                <span class='text'>アカウント</span>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div class='webrtc' v-bind:class={hidden:WebrtcChange}>
    <div class="container">
      <form id="join-form">
        <div class="button-group" style="display:inline-block">
          <div class="join_leave">
            <div id='join' @click="join_form" type='button' class="btn btn-primary btn-sm">
              <div class="icon">
                <img src='@/assets/img/home/icons/join.svg' alt='通話入室'>
              </div>
              <div class="text">入室</div>
              <div class="sub_text">Join</div>
            </div>
            <div id='leave' @click="leave" type='button' class="btn btn-primary btn-sm" style="display: none;">
              <div class="icon">
                <img src='@/assets/img/home/icons/leave.svg' alt='通話退出'>
              </div>
              <div class="text">退出</div>
              <div class="sub_text">Leave</div>
            </div>
          </div>
          <div class="mute_unmute">
            <div id='mute' @click="mute" type='button' class="btn btn-primary btn-sm">
              <div class="icon">
                <img src='@/assets/img/home/icons/mute.svg' alt='ミュート'>
              </div>
              <div class="text">ミュート</div>
              <div class="sub_text">Mute</div>
            </div>
            <div id='unmute' @click="unmute" type='button' class="btn btn-primary btn-sm" style="display: none;">
              <div class="icon">
                <img src='@/assets/img/home/icons/unmute.svg' alt='ミュート解除'>
              </div>
              <div class="text">ミュート解除</div>
              <div class="sub_text">Un Mute</div>
            </div>
          </div>
        </div>
      </form>
      <div class="row video-group">
        <div class="col">
          <div id="local-player" class="player"></div>
        </div>
        <div class="w-100"></div>
        <div class="col">
          <div id="remote-playerlist"></div>
        </div>
      </div>
    </div>
  </div>
  <router-view />
  <nav class='bottom'>
    <div class='box'>
      <div class='MenuContainer'>
        <ul>
          <li class='HomeButton'>
            <router-link to='/' class='' title='ホーム'>
              <div class='button'>ホーム</div>
            </router-link>
          </li>
          <li class='ChatButton'>
            <router-link to='/chat' class='' title='チャット'>
              <div class='button'>チャット</div>
            </router-link>
          </li>
          <li class='SosContactButton'>
            <router-link to='/sos_contact' class='' title='緊急連絡'>
              <div class='button'>緊急連絡</div>
            </router-link>
          </li>
          <li class='SmartAppliancesButton'>
            <router-link to='/smart_appliances' class='' title='スマート家電'>
              <div class='button'>スマート家電</div>
            </router-link>
          </li>
          <li class='E-LearningButton'>
            <router-link to='/e-learning' class='' title='E-ラーニング'>
              <div class='button'>E-ラーニング</div>
            </router-link>
          </li>
          <li class='NetFlyerButton'>
            <router-link to='/netflyer' class='' title='ネットチラシ'>
              <div class='button'>ネットチラシ</div>
            </router-link>
          </li>
          <li class='DeliveryButton'>
            <router-link to='/delivery' class='' title='デリバリー'>
              <div class='button'>デリバリー</div>
            </router-link>
          </li>
          <li class='WeatherNewsButton'>
            <router-link to='/weathernews' class='' title='天気・NEWS'>
              <div class='button'>天気・NEWS</div>
            </router-link>
          </li>
          <li class='SettingsButton'>
            <router-link to='/settings' class='' title='各種設定'>
              <div class='button'>各種設定</div>
            </router-link>
          </li>
          <li class='HelpButton'>
            <a href='https://support.conemoni.com/' target='_blank' rel='noopener noreferrer' class='' title='ヘルプ'>
              <div class='button'>ヘルプ</div>
            </a>
          </li>
        </ul>
      </div>
      <!--
      <div class='FlyerContainer'>
        <picture>
          <source srcset='@/assets/img/flyer_test1.webp' type='image/webp'>
          <img src='@/assets/img/flyer_test1.png'>
        </picture>
        <picture>
          <source srcset='@/assets/img/flyer_test2.webp' type='image/webp'>
          <img src='@/assets/img/flyer_test2.png'>
        </picture>
        <picture>
          <source srcset='@/assets/img/flyer_test3.webp' type='image/webp'>
          <img src='@/assets/img/flyer_test3.png'>
        </picture>
      </div>
      -->
      <div class='CallMenuContainer'>
        <div class='MuteUnMuteButton'></div>
        <div class='JoinLeaveButton'></div>
      </div>
    </div>
  </nav>
</template>

<script>
import $ from 'jquery'
import AgoraRTC from 'agora-rtc-sdk-ng'
import axios from 'axios'

let client

export default {
  data () {
    return {
      user: 'ゲスト',
      localTracks: {
        videoTrack: null,
        audioTrack: null
      },
      remoteUsers: {},
      options: {
        appid: 'bc277f389ff34bd3b819b17cfe093ce7',
        channel: 'tests',
        uid: null,
        token: null
      }
    }
  },
  computed: {
    WebrtcChange () {
      return this.$store.state.WebrtcHidden
    }
  },
  watch: {
    $route (to, from) {
      if (to.name !== 'home') {
        this.$store.commit('setWebrtc', true)
      } else {
        this.$store.commit('setWebrtc', false)
      }
    }
  },
  async created () {
    // ログインの確認
    const cookies = this.$cookies.keys()
    const check = cookies.find(value => {
      if (value.match(/^auth0./g) && value.match(/is.authenticated$/g)) {
        return true
      } else { return false }
    })

    if (check === undefined) { // ログインしていない
      if (location.pathname === '/login' || location.pathname === '/logout' || location.pathname === '/') {
        this.$router.push({ path: '/login' })
      } else {
        this.$router.push({ path: '/login', query: { returnUri: encodeURIComponent(location.href) } })
      }
    } else { // ログインしている
      this.user = this.$auth0.user
    }

    // UIDの確認と作成
    if (!this.$cookies.get('uid')) {
      const uid = ('0000000' + Math.floor(Math.random() * 10000000)).slice(-7)
      this.$store.commit('setUser', uid)
      this.$cookies.set('uid', uid, Infinity)
    } else {
      this.$store.commit('setUser', this.$cookies.get('uid'))
    }
  },
  async mounted () {
    // 現在のブラウザの互換性を確認
    if (AgoraRTC.checkSystemRequirements()) {
      // カメラとマイクの権限を要求
      if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
        navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      }

      // クッキーに保存済みのカメラとマイクのデバイスidを取得
      if (this.$cookies.get('cam_deviceid')) {
        this.$store.commit('setCamera', this.$cookies.get('cam_deviceid'))
      }
      if (this.$cookies.get('mic_deviceid')) {
        this.$store.commit('setMic', this.$cookies.get('mic_deviceid'))
      }

      // AgoraのTokenを取得
      if (!this.$cookies.get('token')) {
        await axios.get(`https://api-test-001.conemoni.com/rtc/token?channel_name=${this.options.channel}&uid=${this.$store.state.uid}`)
          .then(response => {
            // handle success(axiosの処理が成功した場合に処理させたいことを記述)
            console.log(response)
            this.$store.commit('setWebrtcToken', response.data.access_token)
            this.$cookies.set('token', this.$store.state.WebrtcToken, '1d')
          })
          .catch(error => {
            // handle error(axiosの処理にエラーが発生した場合に処理させたいことを記述)
            console.log(error)
          })
      } else {
        await this.$store.commit('setWebrtcToken', this.$cookies.get('token'))
      }

      AgoraRTC.onAutoplayFailed = () => {
        alert('click to start autoplay!')
      }

      this.localTracks.videoTrack && (this.localTracks.videoTrack.setEncoderConfiguration('1080p_2'))

      this.options.uid = this.$store.state.uid
      this.options.token = this.$store.state.WebrtcToken
    }
  },
  methods: {
    async initDevices () {
      if (!this.localTracks.audioTrack) {
        this.localTracks.audioTrack = await AgoraRTC.createMicrophoneAudioTrack()
      }
      if (!this.localTracks.videoTrack) {
        this.localTracks.videoTrack = await AgoraRTC.createCameraVideoTrack({
          encoderConfig: '1080p_2'
        })
      }
      // switch device of local audio track.
      await this.localTracks.audioTrack.setDevice(this.$store.state.MicDeviceId)

      // switch device of local video track.
      await this.localTracks.videoTrack.setDevice(this.$store.state.CameraDeviceId)
    },
    async join () {
      // Add an event listener to play remote tracks when remote user publishes.
      client.on('user-published', this.handleUserPublished)
      client.on('user-unpublished', this.handleUserUnpublished)
      // Join the channel.
      this.options.uid = await client.join(this.options.appid, this.options.channel, this.options.token || null, this.options.uid || null)
      if (!this.localTracks.audioTrack) {
        this.localTracks.audioTrack = await AgoraRTC.createMicrophoneAudioTrack()
      }
      if (!this.localTracks.videoTrack) {
        this.localTracks.videoTrack = await AgoraRTC.createCameraVideoTrack({
          encoderConfig: '1080p_2'
        })
      }

      // Play the local video track to the local browser and update the UI with the user ID.
      this.localTracks.videoTrack.play('local-player')
      $('#joined-setup').css('display', 'flex')

      // Publish the local video and audio tracks to the channel.
      await client.publish(Object.values(this.localTracks))
      console.log('publish success')
    },
    async leave () {
      for (const trackName in this.localTracks) {
        const track = this.localTracks[trackName]
        if (track) {
          track.stop()
          track.close()
          this.localTracks[trackName] = undefined
        }
      }

      // Remove remote users and player views.
      this.remoteUsers = {}
      $('#remote-playerlist').html('')
      $('#remote-player-status').html('')

      // leave the channel
      await client.leave()
      $('#join').css('display', 'flex')
      $('#leave').css('display', 'none')
      $('#joined-setup').css('display', 'none')
      console.log('client leaves channel success')
    },
    async mute () {
      if (this.localTracks.audioTrack) {
        await this.localTracks.audioTrack.setMuted(true)
      }
      $('#unmute').css('display', 'flex')
      $('#mute').css('display', 'none')
    },
    async unmute () {
      if (this.localTracks.audioTrack) {
        await this.localTracks.audioTrack.setMuted(false)
      }
      $('#mute').css('display', 'flex')
      $('#unmute').css('display', 'none')
    },
    async subscribe (user, mediaType) {
      const uid = user.uid
      // subscribe to a remote user
      await client.subscribe(user, mediaType)
      console.log('subscribe success')
      if (mediaType === 'video') {
        const PlayerPlay = $(`
      <div id='player-wrapper-${uid}'>
        <div id='player-${uid}' class='player'></div>
      </div>
    `)
        const PlayerName = $(`
      <div id='player-wrapper-${uid}'>
        <p class='player-name'>リモートユーザー(${uid})さん</p>
      </div>
    `)
        $('#remote-playerlist').append(PlayerPlay)
        $('#remote-player-status').append(PlayerName)
        user.videoTrack.play(`player-${uid}`)
      }
      if (mediaType === 'audio') {
        user.audioTrack.play()
      }
    },
    handleUserPublished (user, mediaType) {
      const id = user.uid
      this.remoteUsers[id] = user
      this.subscribe(user, mediaType)
    },
    handleUserUnpublished (user, mediaType) {
      if (mediaType === 'video') {
        const id = user.uid
        delete this.remoteUsers[id]
        $(`#player-wrapper-${id}`).remove()
      }
    },
    async join_form () {
      try {
        if (!client) {
          client = AgoraRTC.createClient({
            mode: 'rtc',
            codec: 'vp8'
          })
        }
        await this.initDevices()
        await this.join()
      } catch (error) {
        console.error(error)
      } finally {
        $('#leave').css('display', 'flex')
        $('#join').css('display', 'none')
      }
    }
  }
}

AgoraRTC.onMicrophoneChanged = async changedDevice => {
  // When plugging in a device, switch to a device that is newly plugged in.
  if (changedDevice.state === 'ACTIVE') {
    this.localTracks.audioTrack.setDevice(changedDevice.device.deviceId)
    // Switch to an existing device when the current device is unplugged.
  } else if (changedDevice.device.label === this.localTracks.audioTrack.getTrackLabel()) {
    const oldMicrophones = await AgoraRTC.getMicrophones()
    oldMicrophones[0] && this.localTracks.audioTrack.setDevice(oldMicrophones[0].deviceId)
  }
}
AgoraRTC.onCameraChanged = async changedDevice => {
  // When plugging in a device, switch to a device that is newly plugged in.
  if (changedDevice.state === 'ACTIVE') {
    this.localTracks.videoTrack.setDevice(changedDevice.device.deviceId)
    // Switch to an existing device when the current device is unplugged.
  } else if (changedDevice.device.label === this.localTracks.videoTrack.getTrackLabel()) {
    const oldCameras = await AgoraRTC.getCameras()
    oldCameras[0] && this.localTracks.videoTrack.setDevice(oldCameras[0].deviceId)
  }
}
</script>

<style lang='scss'>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
}

a {
  text-decoration: none;
  cursor: pointer;
}

nav {
  background-color: #F7F7F7;
  display: flex;
  align-items: center;

  li {
    list-style: none;
  }
}

nav.top {
  height: 85px;

  .box {
    width: 100%;
    display: flex;
    position: relative;
    margin: 0 30px;
  }

  .MenuContainer {
    position: absolute;
    right: 0;
    margin-top: 3px;

    ul {
      display: flex;
    }

    li {
      display: grid;
      place-items: center;
      margin: 0 4px;
    }

    .RemoteUser {
      display: table;
      padding: 0 10px;
      text-align: left;

      .container {
        display: table-cell;
        vertical-align: middle;
      }
    }

    .NameDisplay {
      display: table;
      padding: 0 10px;
      text-align: left;

      .container {
        display: table-cell;
        vertical-align: middle;
      }

      .name {
        font: normal normal bold 16.5px MigMix;
        letter-spacing: -0.8px;
        line-height: 18px;
      }

      .lastlogin {
        font: normal normal bold 11.5px MigMix;
      }
    }

    .MoneyDisplay .MoneyBalanceDisplay p.top {
      padding-right: 12px;
      text-align: right;
      font: normal normal bold 14px MigMix;
      color: #828282;
    }

    .MoneyDisplay .MoneyBalanceDisplay .DisplayContainer {
      width: 165px;
      height: 35px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 1px solid #707070;
      border-radius: 13px;

      p {
        width: 94%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: right;
        font: normal normal bold 19.5px MigMix;
        letter-spacing: -0.5px;
        color: #828282;
      }

      span.currency {
        font: normal normal bold 13px MigMix;
        padding: 5px 0 2px 5px;
      }
    }

    .button {
        position: relative;
        width: 64px;
        height: 52px;

        span.text {
          position: absolute;
          width: 100%;
          font: bold 11.5px MigMix;
          letter-spacing: 1px;
          color: #828282;
          bottom: 3px;
          left: 50%;
          transform: translateX(-50%) scale(0.8);
        }
      }
  }
}

.webrtc {
  height: calc(100vh - 165px);

  &.hidden {
    visibility: hidden;
    height: 0;
  }

  h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }

  h3 {
    margin: 40px 0 0;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 10px;
  }

  a {
    color: #42b983;
  }

  #join-form {
    position: absolute;
    right: 30px;
    bottom: 95px;
    z-index: 2;
  }

  .join_leave {
    padding: 0 2.5px;
  }

  #join,
  #leave {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 70px;
    width: 70px;
    border-radius: 13px;
    border: none;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .05);
    margin-bottom: 15px;

    img {
      width: 100%;
      height: 100%;
    }

    .icon {
      width: 25px;
      height: 25px;
      padding: 5px 0;
    }

    .text {
      font-size: 11px;
      font-weight: bold;
      height: 15px;
      letter-spacing: 2px;
    }

    .sub_text {
      font-size: 10px;
      font-weight: 100;
      letter-spacing: 0.8px;
    }
  }

  #join {
    background-image: linear-gradient(90deg, rgba(236, 236, 236, 1), rgba(255, 255, 255, 1) 50%);
    color: #6688f9;
  }

  #leave {
    background-image: linear-gradient(90deg, rgba(236, 236, 236, 1), rgba(255, 255, 255, 1) 50%);
    color: #ff0000;
  }

  #mute,
  #unmute {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 75px;
    width: 75px;
    border-radius: 50%;
    border: none;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .05);
    color: #FFFFFF;

    img {
      width: 100%;
      height: 100%;
    }

    .icon {
      width: 25px;
      height: 25px;
      padding: 5px 0;
    }

    .text {
      transform:scale(0.85);
      font-size: 10px;
      font-weight: bold;
      height: 15px;
    }

    .sub_text {
      transform:scale(0.8);
      font-size: 10px;
      font-weight: 100;
      letter-spacing: 1px;
    }
  }

  #mute {
    background-color: #63c076;
  }

  #unmute {
    background-color: #d70033;
  }

  #local-player {
    position: absolute;
    left: 10px;
    top: 95px;
    width: 230px;
    height: 153.3px;
    z-index: 2;
  };

  #remote-playerlist .player {
    margin: 0 auto;
    height: calc(100vh - 165px);
    aspect-ratio: 16/9;
    z-index: 1;
  }

  .player-name {
    margin: 8px 0;
  }

  @media (max-width: 640px) {
    .player {
      width: 320px;
      height: 240px;
    }
  }
}

nav.bottom {
  height: 80px;

  .box {
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;
    margin: 0 10px;
  }

  .MenuContainer {
    position: absolute;
    top: 20px;
    left: 0;

    ul {
      display: flex;
    }

    a {
      text-decoration: none;
      color: #FFFFFF;
      font: normal normal bold 16.5px MigMix;
      letter-spacing: 2.5px;
    }

    li .button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 4px;
      height: 40px;
      width: 140px;
      border-radius: 7px;
      background: linear-gradient(0deg, rgba(73, 170, 166, 1), rgba(105, 201, 197, 1));
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .1);
    }
  }

  .FlyerContainer {
    position: absolute;
    bottom: 10px;
    right: 0;
    left: 0;
    margin: auto;
    max-width: 1425px;

    img {
      width: calc(31vw - 10px / 3);
      max-width: 450px;
      height: auto;
      margin: 0 10px;
    }
  }

}
</style>

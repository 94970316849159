import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookie from 'vue-cookies'
import { createAuth0 } from '@auth0/auth0-vue'

createApp(App).use(store).use(router).use(VueCookie).use(
  createAuth0({
    domain: 'dev-rokogunx.us.auth0.com',
    client_id: '0NQ3LlnUG2A8fOMufJTy4oojemE7AqlF',
    redirect_uri: window.location.origin
  })
).mount('#app')

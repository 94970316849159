import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/chat',
    name: 'chat',
    component: function () {
      return import('../views/ChatView.vue')
    }
  },
  {
    path: '/sos_contact',
    name: 'sos_contact',
    component: function () {
      return import('../views/SosContactView.vue')
    }
  },
  {
    path: '/smart_appliances',
    name: 'smart_appliances',
    component: function () {
      return import('../views/SmartAppliancesView.vue')
    }
  },
  {
    path: '/e-learning',
    name: 'e-learning',
    component: function () {
      return import('../views/E-LearningView.vue')
    }
  },
  {
    path: '/netflyer',
    name: 'netflyer',
    component: function () {
      return import('../views/NetFlyerView.vue')
    }
  },
  {
    path: '/delivery',
    name: 'delivery',
    component: function () {
      return import('../views/DeliveryView.vue')
    }
  },
  {
    path: '/weathernews',
    name: 'weathernews',
    component: function () {
      return import('../views/WeatherNewsView.vue')
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: function () {
      return import('../views/SettingsView.vue')
    }
  },
  {
    path: '/login',
    name: 'login',
    component: function () {
      return import('../views/LoginView.vue')
    }
  },
  {
    path: '/auth0-callback',
    name: 'auth0-callback',
    component: function () {
      return import('../views/Auth0CallbackView.vue')
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: function () {
      return import('../views/LogoutView.vue')
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notfound',
    component: function () {
      return import('../views/NotFoundView.vue')
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

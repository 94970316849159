<template>
  <div class="home" id="browser_error">
    <div class="Error">
      <h1>非対応ブラウザからのアクセスされています。</h1>
      <p>お使いのブラウザはサポートしておりません。別の端末もしくはブラウザからアクセスしなおしてください。</p>
      <p>最新の技術を用いており、以前のバージョンのブラウザでは表示できません。<br>
        セキュリティ上対策の観点からも、最新版のブラウザをご利用ください。</p>
    </div>
  </div>
</template>

<script>
import AgoraRTC from 'agora-rtc-sdk-ng'
import $ from 'jquery'

export default {
  async mounted () {
    // 現在のブラウザの互換性を確認
    if (!AgoraRTC.checkSystemRequirements()) {
      $('.webrtc').html('')
      $('.webrtc').css('display', 'none')
      $('#browser_error').css('display', 'block')
    } else {
      $('.home').css('display', 'none')
    }
  }
}
</script>

<style lang='scss'>
.home {
  height: calc(100vh - 165px);
  position: relative;

  .Error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }

  h1 {
    font-size: 28px;
    margin-bottom: 20px;
  }

  p {
    font-size: 15px;
  }
}
</style>

import { createStore } from 'vuex'

export default createStore({
  state: {
    WebrtcHidden: true,
    WebrtcToken: null,
    CameraDeviceId: null,
    MicDeviceId: null,
    uid: null,
    Message: null
  },
  getters: {
  },
  mutations: {
    setWebrtc (state, status) {
      state.WebrtcHidden = status
    },
    setWebrtcToken (state, token) {
      state.WebrtcToken = token
    },
    setCamera (state, id) {
      state.CameraDeviceId = id
    },
    setMic (state, id) {
      state.MicDeviceId = id
    },
    setUser (state, uid) {
      state.uid = uid
    },
    setMessage (state, report) {
      state.Message = report
    }
  },
  actions: {
  },
  modules: {
  }
})
